<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="preferences"
                :items-per-page="-1"
                :search="searchPrefer"
                :loading="loadingData"
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere!"
                class="elevation-1"
                :footer-props="{
                        showFirstLastPage: false,
                        disableItemsPerPage: false,
                        itemsPerPageAllText: 'Todas',
                        itemsPerPageText: 'Filas por página:'
                }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title>Solicitudes</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-prefer"
                                label="Buscar.."
                                name="findprefer"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchPrefer"
                                class="white--text mt-0 pt-0 mr-2"
                                prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-responsive>
                        <v-btn @click="getContacts"
                               :loading="loadingData"
                               elevation="0"
                               :small="$vuetify.breakpoint.smAndDown"
                               class="mb-2 mr-2"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">¿Est&aacute;s seguro que desea eliminar este elemento?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        :disabled="loadingSave"
                                        @click="closeDelete"
                                    >
                                      Cancelar
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        :loading="loadingSave"
                                        depressed
                                        @click="deleteItemConfirm"
                                    >
                                      Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogAttend" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Desea postear y procesar este contacto?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        :disabled="loadingSave"
                                        @click="closeAttend"
                                    >
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        depressed
                                        @click="attendItemConfirm"
                                        :loading="loadingSave"
                                    >
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.posted="{ item }">
                    <v-simple-checkbox
                        v-model="item.posted"
                        disabled>
                    </v-simple-checkbox>
                </template>
                <template v-slot:item.project_name="{ item }">
                    {{ item.project.name }}
                </template>
                <template v-slot:item.full_name="{ item }">
                    {{ item.investor.user.name }} {{ item.investor.user.last_name }}
                </template>
                <template v-slot:item.email_investor="{ item }">
                    {{ item.investor.user.email }}
                </template>
                <template v-slot:item.date_post="{ item }">
                    <span>{{ formatDate( item.date_post) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="doPostedItem(item)">
                                mdi-account-check
                            </v-icon>
                        </template>
                        <span>¿Contacto Atendido?</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay registros en el listado!</span>
                </template>
            </v-data-table>
            <v-snackbar
                v-model="snackBar"
                :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs"
                           icon
                           @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import preferencesService from "@/providers/PreferencesService";
    
    export default {
        name: "PreferencesModuleComponent",
        data: () => ({
            snackBar: false,
            snackText: '',
            searchPrefer: '',
            loadingData: false,
            loadingSave: false,
            dialog: false,
            dialogDelete: false,
            dialogAttend: false,
            preferences: [],
            headers: [
                {text: 'Id', value: 'id'}, // para ocultar la columna Id.
                {text: "Nombre del Proyecto", value: "project_name" },
                {text: "Nombre del Inversionista", value: "full_name" },
                {text: "E-mail", value: "email_investor" },
                {text: "Fecha Solicitud", value: "date_post"},
                {text: 'Posteado', value: 'posted', align: 'center', width: 100},
                {text: 'Acciones', value: 'actions', width: 20, align: 'center', sortable: false},
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                date_post: new Date(Date.now()).toISOString(),
                project_id: 0,
                investor_id: 0,
                posted: false,
                investor: null,
                project: null
            },
            defaultItem: {
                id: 0,
                date_post: new Date(Date.now()).toISOString(),
                project_id: 0,
                investor_id: 0,
                posted: false,
                investor: null,
                project: null
            },
            nameRules: [
                v => !!v || 'El Nombre es obligatorio!'
                // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter!',
            ],
        }),
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'Nueva preferencia' : 'Editar preferencia'
            },
        },
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
            dialogAttend(val) {
                val || this.closeAttend();
            },
        },
        methods: {
            formatDate(value) {
                if (!value) return null;
                let theDate = value.toString().substr(0,10);
                const [year, month, day] = theDate.split('-')
                return `${day}/${month}/${year}`;
            },
            getContacts() {
                // carga los datos desde el proveedor de datos.
                this.loadingData = true;
                preferencesService.getViewList().then(record => {
                    this.preferences = record.value;
                }).catch(err=>console.log(err)).finally(()=>{
                  this.loadingData = false;
                });
            },
            editItem(item) {
                this.editedIndex = this.preferences.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.preferences.indexOf(item);
                this.editedItem = Object.assign({}, item);
                // console.log('Item to delete: %s %s ', this.editedItem.id, this.editedItem);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.preferences.splice(this.editedIndex, 1);
                this.loadingSave = true;
                preferencesService.deleteRecord(this.editedItem.id).finally(()=>{
                  this.loadingSave = false;
                });
                this.closeDelete();
                this.snackText = 'Registro eliminado con éxito.';
                this.snackBar = true;
            },
            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            doAttendItem(item) {
                // console.log('Logica para atender al contacto...', item);
                this.editedItem = Object.assign({}, item);
                this.dialogAttend = true;
            },
            attendItemConfirm() {
                this.editedItem.attended = true;
                this.loadingSave = true;
                preferencesService.postEmail(this.editedItem).then(result => {
                    this.closeAttend();
                    if (result.success) {
                        this.snackText = 'Contacto procesado y atendido!';
                    } else {
                        this.snackText = 'Un error impidió procesar este contacto!';
                    }
                    this.snackBar = true;
                    this.getContacts();
                }).catch(err=>console.log(err)).finally(()=>{
                  this.loadingSave = false;
                });
            },
            closeAttend() {
                this.dialogAttend = false;
            },
        },
        mounted() {
            this.preferences = [];
            this.getContacts();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>