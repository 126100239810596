<template>
    <preferences-module-component/>
</template>

<script>
    import PreferencesModuleComponent from "@/components/admin/PreferencesModuleComponent";

    export default {
        name: "PreferencesModule",
        title: "Preferencias de Proyectos | Equity Baja",
        components: {PreferencesModuleComponent}
    }
</script>

<style scoped>

</style>